import './tetaHeader.css'
import logo from './Unit4_Teta_Logo.png'

class TetaHeader extends HTMLElement {

    connectedCallback() {
        var appName = this.getAttribute('appName');
        var userName = this.getAttribute('userName');
        var userRoles = this.getAttribute('userRoles');
        var tenantName = this.getAttribute('tenantName');
        var logoutUrl = this.getAttribute('logoutUrl');
        var logoutTxt = this.getAttribute('logoutTxt');

        this.innerHTML = `
        <nav class="nav-container">
            <div class="navbar-container">
                <div class="navbar-header">
                    <ul class="navbar-nav">
                        <li class="app-name">
                            <img src=${logo} alt="Logo" class="logo-teta" />${appName}
                        <li>
                    <ul>
                </div>
                <div class="navbar-right">
                    <ul class="navbar-nav">
                        <li>
                            <div class="user-info">
                                <p class="user-name">${userName}</p>
                                <p class="user-name">${userRoles}</p>
                                <p class="user-tenant">Tenant: ${tenantName}</p>
                            </div>
                        </li>
                        <li class="navbar-logout">
                            <form action=${logoutUrl} id="signoutForm" method="POST">
                                <a href="javascript:document.getElementById('signoutForm').submit()">${logoutTxt}</a>
                            </form>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        `;
    }
}

window.customElements.define('teta-header-component', TetaHeader);