import React from 'react';
import '../../../TetaHeader/tetaHeader.js'
import { ApplicationUser, AuthenticationService } from '../../../../framework/Authentication.service';
import Axios from 'axios';
import * as HttpHelper from "../../../../framework/httpHelper";
import { Spinner } from 'reactstrap';

export interface NavMenuState {
    isLoading: Boolean
    appUser: ApplicationUser
    userTenant: string
}

export class NavMenu extends React.Component<any, NavMenuState> {
    static displayName = NavMenu.name;

    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: true,
            appUser: AuthenticationService.currentUser,
            userTenant: ""
        };
    }

    componentDidMount() {
        this.getUserTenant();
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div>
                    <Spinner color="primary" />
                </div>
            );
        }

        return (
            <header>
                <teta-header-component appName="Teta Access Management"
                    userName={this.state.appUser.userName}
                    userRoles={this.state.appUser.roles}
                    tenantName={this.state.userTenant}
                    logoutUrl="api/account/logout"
                    logoutTxt="Sign out">
                </teta-header-component>
            </header>
        );
    }

    getUserTenant() {
        Axios.get("tenant/getUserTenant")
            .then(response => this.setState({ userTenant: response.data.tenantName, isLoading: false }))
            .catch(err => {
                if (err.message === "Network Error") {
                    HttpHelper.redirectToIdentityProvider();
                } else {
                    this.setState({ userTenant: "Undefined", isLoading: false });
                }
            });
    }
}
