import { EMPTY, Observable } from 'rxjs';

export function redirectToIdentityProvider(): Observable<never> {
    const form = document.createElement('form');

    form.method = 'POST';
    form.action = 'api/account/signin';

    document.body.appendChild(form);

    form.submit();

    return EMPTY;
}
