import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App/App';

import { Provider } from 'react-redux'
import { applyMiddleware, createStore } from 'redux';
import reducer from './framework/store/appReducers';
import thunk from 'redux-thunk';

const rootElement = document.getElementById('root');

ReactDOM.render(
  <Provider store={createStore(reducer, applyMiddleware(thunk))}>
    <App />
  </Provider>,
  rootElement);

