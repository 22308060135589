import Axios from "axios";
import * as HttpHelper from "../../framework/httpHelper";

export function fetchTenantData(tenantId: string) {
  return (dispatch: any) => {
    return Axios.get("tenant/GetByTenantId", {
      params: {
        tenantId: tenantId,
      },
    })
      .then((response) => {
        dispatch({ type: "FETCH_TENANT", payload: response.data });
        return response.data;
      })
      .catch((err) => {
        if (err.message === "Network Error") {
          HttpHelper.redirectToIdentityProvider();
        }
      });
  };
}

export function fetchSourceSystem(sourceSystem: any) {
  return (dispatch: any) => {
    return Axios.get("sourcesystem/getBySourceSystemId", {
      params: {
        sourceSystemId: sourceSystem,
      },
    })
      .then((response) => {
        dispatch({ type: "FETCH_SOURCE_SYSTEM", payload: response.data });
        return response.data;
      })
      .catch((err) => {
        if (err.message === "Network Error") {
          HttpHelper.redirectToIdentityProvider();
        }
      });
  };
}
