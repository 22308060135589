import React from "react";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { TenantDto } from "./ViewModels";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import { ListGroupItem, ListGroup, Spinner } from "reactstrap";
import BaseState from "../../framework/BaseState";
import * as HttpHelper from "../../framework/httpHelper";

interface TenantListState extends BaseState {
    tenants: TenantDto[];
}

export default class TenantList extends React.Component<any, TenantListState> {
    constructor(props: any) {
        super(props);
        this.state = {
            tenants: [],
            isLoading: true,
            errorMessage: "",
        };
    }

    componentDidMount() {
        this.getTenants();
    }

    render() {
        if (this.state.tenants.length === 1) {
            return (
                <Redirect to={`tenant/${this.state.tenants[0].tenantId}/`} />
            );
        }

        if (this.state.isLoading) {
            return (
                <div>
                    <Spinner color="primary" />
                </div>
            );
        }

        if (this.state.errorMessage) {
            return (
                <div>
                    <h3>{this.state.errorMessage}</h3>
                </div>
            );
        }

        const listGroupItems = [];

        for (let tenant of this.state.tenants) {
            listGroupItems.push(
                <Link to={`tenant/${tenant.tenantId}/`} key={listGroupItems.length}>
                    <ListGroupItem>{tenant.tenantName} (id: {tenant.tenantId})</ListGroupItem>
                </Link>
            );
        }

        return (
            <div>
                <h1>Tenants</h1>
                <ListGroup>{listGroupItems}</ListGroup>
            </div>
        );
    }

    getTenants() {
        Axios.get("tenant/get")
            .then(response => this.setState({ tenants: response.data, isLoading: false }))
            .catch(err => {
                if (err.message === "Network Error") {
                    HttpHelper.redirectToIdentityProvider();
                } else {
                    this.setState({ tenants: [], isLoading: false, errorMessage: err.response.data.message });
                }
            });
    }
}
