import React from "react";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import {
    DataGridEditingProps,
    NovaDataGrid,
} from "@teta/nova-controls/data-grid";
import {
    NovaTagBox,
} from "@teta/nova-controls/tag-box";
import Axios from "axios";
import { UserRoleDto } from "./ViewModels";
import BaseState from "../../framework/BaseState";
import { Spinner } from "reactstrap";
import { Column, Lookup, Button as GridButton, Selection } from "devextreme-react/data-grid";
import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import * as HttpHelper from "../../framework/httpHelper";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {fetchSourceSystem, fetchTenantData} from '../../framework/store/appActions';

interface UserListState extends BaseState {
    tenantId: string;
    sourceSystemId: string;
    sourceSystemName: string;
    availableRoles: string[];
    users: UserRoleDto[];
    selectedUsers: UserRoleDto[];
    selectedGroupRole: string[];
    isGroupRolesSettingPopupVisible: boolean;
    isGroupRolesClearPopupVisible: boolean;
}

class UserList extends React.Component<any, UserListState> {
    static editing: DataGridEditingProps = {
        allowUpdating: true,
        mode: "batch",
    };

    static filterPanel = { visible: true };
    static filterRow = { visible: true };
    static headerFilter = { visible: true, allowSearch: true };

    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: true,
            tenantId: this.props.match.params.tenantId,
            sourceSystemId: this.props.match.params.sourceSystemId,
            sourceSystemName: "",
            availableRoles: [],
            users: [],
            errorMessage: "",
            selectedUsers: [],
            selectedGroupRole: [],
            isGroupRolesSettingPopupVisible: false,
            isGroupRolesClearPopupVisible: false
        };

        this.rolesEditorRender = this.rolesEditorRender.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);

        this.onOpenGroupRolesSettingPopup = this.onOpenGroupRolesSettingPopup.bind(this);
        this.onHideGroupRolesSettingPopup = this.onHideGroupRolesSettingPopup.bind(this);
        this.onGroupRolesUpdateApproved = this.onGroupRolesUpdateApproved.bind(this);
        this.onClickClearRoles = this.onClickClearRoles.bind(this);
        this.onGroupRolesClearApproved = this.onGroupRolesClearApproved.bind(this);
        this.onHideGroupRolesClearPopup = this.onHideGroupRolesClearPopup.bind(this);
        this.onOpenGroupRolesClearPopup = this.onOpenGroupRolesClearPopup.bind(this);
    }

    onValueChanged(cell: any, e: any) {
        cell.setValue(e);
    }

    onSelectionChanged(selectedUsers: UserRoleDto[]) {
        this.setState({ selectedUsers: selectedUsers })
    }

    onOpenGroupRolesSettingPopup() {
        this.setState({
            isGroupRolesSettingPopupVisible: true
        });
    }

    onHideGroupRolesSettingPopup() {
        this.setState({
            isGroupRolesSettingPopupVisible: false,
        });
    }

    onOpenGroupRolesClearPopup() {
        this.setState({
            isGroupRolesClearPopupVisible: true
        });
    }

    onHideGroupRolesClearPopup() {
        this.setState({
            isGroupRolesClearPopupVisible: false,
        });
    }

    onGroupRolesUpdateApproved() {
        this.state.selectedUsers.forEach(user => {
            user.roles = this.state.selectedGroupRole;
            this.updateRow(user);
        });
        this.setState({ selectedUsers: [] })
    }

    onGroupRolesClearApproved() {
        this.state.selectedUsers.forEach(user => {
            this.clearRoles(user);
        });
        this.setState({ selectedUsers: [] })
    }

    onRolesForGroupUpdateSelected(selectedRole: string[]) {
        this.setState({
            selectedGroupRole: selectedRole
        })
    }

    rolesEditorRender(cell: any) {
        let onValueChanged = this.onValueChanged.bind(this, cell);

        return <NovaTagBox
            dataSource={this.state.availableRoles}
            defaultValue={cell.value}
            showSelectionControls={true}
            onValueChanged={onValueChanged}
        />;
    }

    cellTemplate(container:any, options:any) {
        var text = (options.value || []).map((element: any) => {
            return options.column.lookup.calculateCellValue(element);
          }).join(', ');
        container.textContent = text;
        container.title = text;
      }

    onError(errorMsg: string) {
        this.setState({
            users: [],
            availableRoles: [],
            isLoading: false,
            errorMessage: errorMsg,
            isGroupRolesSettingPopupVisible: false
        });
    }

    componentDidMount() {
        this.getUsers();
        this.props.fetchSourceSystem(this.state.sourceSystemId);
        this.props.fetchTenantData(this.state.tenantId);
    }

    calculateFilterExpression(filterValue: any, selectedFilterOperation: any, target: string) {
        return function (data: any) {
          return (data.roles || []).indexOf(filterValue) !== -1;
        };
      }

    render() {
        if (this.state.isLoading) {
            return (
                <div>
                    <Spinner color="primary" />
                </div>
            );
        }

        if (this.state.errorMessage) {
            return (
                <div>
                    <h3>{this.state.errorMessage}</h3>
                </div>
            );
        }

        const { editing, filterPanel, filterRow, headerFilter } = UserList;

        return (
            <div>
                <h1>Users</h1>
                {this.props.tenant !== undefined && 
                    <p>Current tenant: <strong>{this.props.tenant.tenantName}</strong></p>}
                    {this.props.sourceSystem !== undefined && 
                    <p>Current source system: <strong>{this.props.sourceSystem.sourceSystemName}</strong></p>}
                <div>
                    <Popup
                        title="Group role setting"
                        width="300"
                        height="300"
                        closeOnOutsideClick={true}
                        visible={this.state.isGroupRolesSettingPopupVisible}
                        onHiding={this.onHideGroupRolesSettingPopup}
                    >
                        <NovaTagBox
                            dataSource={this.state.availableRoles}
                            onValueChanged={(data) => this.onRolesForGroupUpdateSelected(data as string[])}
                            showSelectionControls={true}
                        >
                        </NovaTagBox>
                        <Button
                            text="Approve"
                            disabled={this.state.selectedGroupRole.length === 0}
                            onClick={this.onGroupRolesUpdateApproved}>
                        </Button>
                    </Popup>
                    <Popup
                        title="Group role cleaning"
                        width="300"
                        height="300"
                        closeOnOutsideClick={true}
                        visible={this.state.isGroupRolesClearPopupVisible}
                        onHiding={this.onHideGroupRolesClearPopup}
                    >
                        <h5>Are you sure to clear roles for selected users?</h5>
                        <Button
                            text="Approve"
                            onClick={this.onGroupRolesClearApproved}>
                        </Button>
                    </Popup>
                    <Button
                        text="Set role for selected users"
                        disabled={this.state.selectedUsers.length === 0}
                        onClick={this.onOpenGroupRolesSettingPopup}>
                    </Button>
                    <Button
                        text="Clear roles for selected users"
                        disabled={this.state.selectedUsers.length === 0}
                        onClick={this.onOpenGroupRolesClearPopup}>
                    </Button>
                </div>
                <NovaDataGrid
                    dataSource={this.state.users}
                    editing={editing}
                    showColumnLines={true}
                    showRowLines={true}
                    showBorders={true}
                    filterPanel={filterPanel}
                    filterRow={filterRow}
                    headerFilter={headerFilter}
                    keyExpr="userGuid"
                    onRowUpdated={(value) => this.updateRow(value)}
                    onSelectionChanged={(value) => this.onSelectionChanged(value.selectedRowsData as UserRoleDto[])}
                >
                    <Selection
                        mode="multiple"
                        selectAllMode="allPages"
                        showCheckBoxesMode="onClick">
                    </Selection>
                    <Column
                        dataField="username"
                        caption="Username"
                        allowEditing={false}
                    >
                    </Column>
                    <Column
                        dataField="userPersonData"
                        caption="First and last name"
                        allowEditing={false}
                    >
                    </Column>
                    <Column
                        dataField="roles"
                        caption="Roles"
                        editCellRender={(value) => this.rolesEditorRender(value)}
                        cellTemplate={this.cellTemplate}
                        calculateFilterExpression={this.calculateFilterExpression}
                    >
                        <Lookup
                            dataSource={this.state.availableRoles}
                        />
                    </Column>
                    <Column type="buttons">
                        <GridButton
                            text="Clear roles"
                            onClick={this.onClickClearRoles}>
                        </GridButton>
                    </Column>
                </NovaDataGrid>
            </div>
        );
    }

    async updateRow(userViewModel: UserRoleDto) {
        Axios.put(`userrole/put`, userViewModel)
            .then(response => this.getUsers())
            .catch(err => {
                if (err.message === "Network Error") {
                    HttpHelper.redirectToIdentityProvider();
                } else {
                    this.onError(err.response.data.message);
                }
            });
    }

    async onClickClearRoles(event: any) {
        this.clearRoles(event.row.data);
    }

    async clearRoles(userRole: UserRoleDto) {
        this.setState({
            isLoading:true
        });
        Axios.put(`userrole/clearRoles`, userRole)
            .then(() => {
                this.getUsers()
            })
            .catch(err => {
                if (err.message === "Network Error") {
                    HttpHelper.redirectToIdentityProvider();
                } else {
                    this.onError(err.response.data.message);
                }
            });
    }

    async getUsers() {
        Axios.get("userrole/getByTenantId", {
            params: {
                tenantId: this.state.tenantId,
                sourceSystemId: this.state.sourceSystemId,
            },
        })
            .then(response => this.setState({
                users: response.data.userRoles,
                availableRoles: response.data.availableRoles,
                isLoading: false,
                isGroupRolesSettingPopupVisible: false
            }))
            .catch(err => {
                if (err.message === "Network Error") {
                    HttpHelper.redirectToIdentityProvider();
                } else {
                    this.onError(err.response.data.message);
                }
            });
    }
}
const mapStateToProps = (state:any) => ({
    ...state
})
export default withRouter(connect(mapStateToProps, {fetchSourceSystem, fetchTenantData})(UserList));