import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { Layout } from "./Layout/Layout";
import TenantList from "../Tenant/TenantList";
import SourceSystemList from "../Tenant/SourceSystemList";
import UserList from "../Tenant/UserList";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Axios from "axios";
import { AuthenticationService } from "../../framework/Authentication.service";
import { Spinner } from "reactstrap";
import Breadcrumbs from "../../framework/Breadcrumbs";

export interface AppState {
    isLoading: Boolean;
    errorMessage: string;
}

export default class App extends React.Component<any, AppState> {
    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: true,
            errorMessage: "",
        };
        Axios.defaults.baseURL = process.env.PUBLIC_URL + "api/";
        AuthenticationService.updateUser()
            .then(response => this.setState({ isLoading: false, errorMessage: "" }))
            .catch(err => this.setState({ isLoading: false, errorMessage: err.response.data.message }));
    }

    render() {
        if (this.state.isLoading) {
            return <Spinner color="primary" />;
        }

        if (this.state.errorMessage) {
            return (
                <BrowserRouter>
                    <Layout>
                        <div>
                            <h3>{this.state.errorMessage}</h3>
                        </div>
                    </Layout>
                </BrowserRouter>
            );
        }

        return (
            <BrowserRouter>
                <Layout>
                <Breadcrumbs />
                    <Switch>
                        <Route exact path="/" component={TenantList} />
                        <Route exact path="/tenant/:tenantId" component={SourceSystemList} />
                        <Route exact path="/tenant/:tenantId/sourcesystem/:sourceSystemId" component={UserList} />
                        <Route render={() => <Redirect to="/" />} />
                    </Switch>
                </Layout>
            </BrowserRouter>
        );
    }
}
