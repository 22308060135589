import Axios from "axios";
import * as HttpHelper from "./httpHelper";

export interface ApplicationUser {
    roles: String[];
    tenantId: String;
    userName: String;
}

export const AuthenticationService = {

    get currentUser(): ApplicationUser {
        return JSON.parse(
            localStorage.getItem("currentUser") || "{}"
        ) as ApplicationUser;
    },

    updateUser() {
        return Axios.get("user/getUser")
            .then((response) => {
                localStorage.setItem("currentUser", JSON.stringify(response.data));
            })
            .catch((err) => {
                if (err.message === "Network Error") {
                    HttpHelper.redirectToIdentityProvider();
                } else {
                    throw err;
                }
            });
    },
};
