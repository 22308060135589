import React from "react";
import { emphasize, withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import { Breadcrumbs as MUBreadcrumbs } from "@material-ui/core";
import { withRouter } from "react-router-dom";

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(6),
    fontSize: (theme.fontSize = 16),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightMedium,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip);

const Breadcrumbs = (props) => {
  const {
    history,
    location: { pathname },
  } = props;
  const names = { tenant: "Tenant Source Systems", sourcesystem: "Users" };
  const pathnames = pathname.split("/").filter((item) => item);
  let label;

  let styles = {
    marginBottom: "35px"
  };

  return (
    <div style={styles}>
      <MUBreadcrumbs aria-label="breadcrumb">
        {pathnames.length > 0 ? (
          <StyledBreadcrumb label="Tenants" onClick={() => history.push("/")} />
        ) : (
          <StyledBreadcrumb label="Tenants" />
        )}
        {pathnames.map((id, index) => {
          if (names[pathnames[index]] !== undefined) {
            label = names[pathnames[index]];
            return;
          }
          const routeTo = `/${pathnames.slice(0, index + 1).join("/")}/`;
          const isLast = index === pathnames.length - 1;
          return isLast ? (
            <StyledBreadcrumb label={label} key={index} />
          ) : (
            <StyledBreadcrumb
              label={label}
              onClick={() => history.push(routeTo)}
              key={index}
            />
          );
        })}
      </MUBreadcrumbs>
    </div>
  );
};

export default withRouter(Breadcrumbs);
