export default (state:any, action:any) => {
    switch (action.type) {
    case 'FETCH_TENANT':
      return {
        ...state,
        tenant: action.payload
    };
    case 'FETCH_SOURCE_SYSTEM':
      return {
        ...state,
        sourceSystem: action.payload
    };
    default:
      return state;
    }
};