import React from "react";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import Axios from "axios";
import { SourceSystemDto } from "./ViewModels";
import { ListGroup, ListGroupItem, Spinner } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import BaseState from "../../framework/BaseState";
import * as HttpHelper from "../../framework/httpHelper";
import { connect } from "react-redux";
import {fetchTenantData} from '../../framework/store/appActions';

interface SourceSystemListState extends BaseState {
    tenantId: String;
    sourceSystemDtos: SourceSystemDto[];
}

class SourceSystemList extends React.Component<
    any,
    SourceSystemListState
    > {
    constructor(props: any) {
        super(props);
        this.state = {
            tenantId: this.props.match.params.tenantId,
            sourceSystemDtos: [],
            isLoading: true,
            errorMessage: ""
        };
    }

    componentDidMount() {
        this.getSourceSystems();
        this.props.fetchTenantData(this.state.tenantId);     
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div>
                    <Spinner color="primary" />
                </div>
            );
        }

        if (this.state.errorMessage) {
            return (
                <div>
                    <h3>{this.state.errorMessage}</h3>
                </div>
            );
        }

        const listGroupItems = [];

        for (let sourceSystem of this.state.sourceSystemDtos) {
            listGroupItems.push(
                <Link to={`sourcesystem/${sourceSystem.sourceSystemId}`} key={listGroupItems.length}>
                    <ListGroupItem>{sourceSystem.sourceSystemName} (id: {sourceSystem.sourceSystemId}) (type: {sourceSystem.sourceSystemType})</ListGroupItem>
                </Link>
            );
        }

        return (
            <div>

                <h1>Tenant Source Systems</h1>
                {this.props.tenant !== undefined && 
                <p>Current tenant: <strong>{this.props.tenant.tenantName}</strong></p>}
                <ListGroup>{listGroupItems}</ListGroup>
            </div>
        );
    }

    async getSourceSystems() {
        Axios.get("sourcesystem/getByTenantId", {
            params: {
                tenantId: this.state.tenantId,
            },
        })
            .then(response => this.setState({ sourceSystemDtos: response.data, isLoading: false }))
            .catch(err => {
                if (err.message === "Network Error") {
                    HttpHelper.redirectToIdentityProvider();
                } else {
                    this.setState({ sourceSystemDtos: [], isLoading: false, errorMessage: err.response.data.message });
                }
            });
    }
}
const mapStateToProps = (state:any) => ({
    ...state
})
export default withRouter(connect(mapStateToProps, {fetchTenantData})(SourceSystemList));